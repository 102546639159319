.sui-search-box__submit {
  flex-shrink: 0;
  font-family: inherit;
  font-size: 14px;
  padding: 16px;
  margin-left: 10px;
  text-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
  color: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset, rgba(59, 69, 79, 0.05) 0px 1px 0px;
  background: linear-gradient(#50cb87, #01b150) #02b452;
  cursor: pointer;
  border-radius: 4px;
}
.sui-search-box__submit:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 1px inset, rgba(59, 69, 79, 0.3) 0px 2px 4px;
    background: linear-gradient(#ff5e70, #f04046) #f73d3d;
  }
